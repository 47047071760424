.materia-grid {
  display: flex;
  flex-wrap: wrap;
  .unit-item {
    width: 25%;
    box-sizing: border-box;
    padding: 10px;

    .unit-image {
      position: relative;
      overflow: hidden;
      border: 5px solid #e6e6e6;
      img {
        width: 100%;
        transition: all .2s ease-in-out;

      }
      &:hover {
        img {
          -ms-transform: scale(1.1); /* IE 9 */
          -webkit-transform: scale(1.1); /* Safari */
          transform: scale(1.1);
        }
        .unit-title {
          min-height: 60px;
        }
      }

    }
    .unit-title {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      //height:40px;
      box-sizing: border-box;
      color: #fff;
      padding: 10px;
      font-size: 13px;
      text-transform: uppercase;
      font-weight: 600;
      min-height: 50px;
      transition: all .2s ease-in-out;

      background: rgba(0, 0, 0, 0.8);

    }
    .activities {

      > ul {
        margin: 10px 0;
        padding: 0;
        > li {
          padding: 4px 0;
          list-style-type: none;
          a {
            color: #000;
            &:hover {
              color: $segundo_ciclo;
            }
          }
          .icon-holder {
            width: 30px;
            text-align: center;
            i {
              position: relative;
              top: 7px;
              font-size: 23px;
            }
          }

        }
      }

    }
  }
  @media screen and (max-width: $tablet-small) {
    .unit-item {
      width: 100%;
    }

  }
  @media screen and (max-width: $break-small) {
    .unit-item {
      width: 100%;
    }

  }
}