.homepage {
  .home-navigation {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 5vh;
    z-index:3;

    > ul {
      margin: 0;
      padding: 0px;
      width: 100%;
      display: flex;
      justify-content: space-around;
      > li {
        list-style-type: none;
        position: relative;
        width: 17%;
        padding: 0 0 13px 0;
        border-radius: 20px;
        background-color: #5c6556;
        text-align: center;
        font-weight: 600;
        min-height: 85px;
        -webkit-transition: all 1s; /* Safari */
        transition: all 1s;

        > a {
          font-size: 20px;

          color: #fff;
          text-align: center;
          > span {
            text-align: center;

            display: block;
            position: relative;
            top: 10px;

          }
        }
        &:hover {
          transform: translate(0,-3vh) ;
          .dropdown {
            display: block;
          }
        }
        .dropdown {
          display: none;

          background: inherit;
          position: absolute;
          left: 0;
          top: 85%;
          width: 100%;
          padding: 10px 5px 25px 5px;
          box-sizing: border-box;
          margin: 0;
          border-bottom-left-radius: 25px;
          border-bottom-right-radius: 25px;

          > li {
            background: rgba(255, 255, 255, 0.52);
            text-align: left;
            width: 100%;
            padding: 4px 4px;
            box-sizing: border-box;
            list-style-type: none;
            border-bottom: 1px solid rgba(255, 255, 255, 0.4);
            width: 100%;
            > a {
              color: #000;
              width: 100%;
              font-weight: 400;
              position:relative;
              display: block;

              i{
                right: 5px;
                position: absolute;
              }


              &:hover {
                font-weight: 400;

              }


            }
            .dropdown-2 {
              padding: 0;
              margin: 0 0 0 10px;
              display: none;

              > li {
                list-style-type: none;
                text-align: left;
                //font-size: 13px;
                width: 100%;
                border-top: 1px solid rgba(255, 255, 255, 0.4);

                > a {
                  color: #000;
                  font-weight: 400;
                  //padding: 10px 0px;

                  font-size: 14px;
                  &:hover {
                    font-weight: 400;

                  }
                }
              }
            }
            &.active {
              > a {
                font-weight: 600;
                i{
                  color:#eee;
                }

              }
            }
          }
        }
      }
      > li:nth-child(1) {
        $color: $inicial;
        background: $color;
        > a {
          font-size: 35px;
          position: relative;
          > span {
            font-size: 27px;
          }
        }
        .dropdown > li.active {
          > a {
            color: $color;
          }
        }
        .dropdown,
        .dropdown-2 {
          a:hover {
            color: $color;
          }
        }
      }
      > li:nth-child(2) {
        $color: $primer_ciclo;
        background: $color;
        > a {
          font-size: 27px;
          > span {
            font-size: 35px;
          }
        }
        .dropdown > li.active {
          > a {
            color: $color;

          }
        }
        .dropdown,
        .dropdown-2 {
          a:hover {
            color: $color;
          }
        }
      }
      > li:nth-child(3) {
        $color: $segundo_ciclo;

        background: $color;
        > a {
          font-size: 27px;
          > span {
            font-size: 35px;
          }
        }
        .dropdown > li.active {
          > a {
            color: $color;

          }
        }
        .dropdown,
        .dropdown-2 {
          a:hover {
            color: $color;
          }
        }
      }

      > li:nth-child(4) {
        $color: $secundaria_basica;
        background: $color;

        > a {
          font-size: 40px;
          > span {
            font-size: 24px;
          }
        }
        .dropdown > li.active {
          > a {
            color: $color;
          }
        }
        .dropdown {
          a:hover {
            color: $color;
          }
        }
      }
      > li:nth-child(5) {
        $color: $secundaria_superior;
        background: $color;

        > a {
          font-size: 39px;
          > span {
            font-size: 24px;
          }
        }
        .dropdown > li.active {
          > a {
            color: $color;
          }
        }
        .dropdown,
        .dropdown-2 {
          a:hover {
            color: $color;
          }
        }
      }
    }

    @media screen and (max-width: $break-large) {
      width: 90%;

      margin-top: 4vh;

      > ul {

        > li {
          min-height: 50px;
          padding: 5px 0 13px 0;
          > a {

            > span {
              position: relative;
              top: 5px;

            }
          }
          .dropdown {
            top: 77%;
            >li{
              font-size:12px;
              .dropdown-2{
                >li{                    margin:0;

                  a{
                    font-size:12px;
                    line-height: 12px;

                  }
                }
              }
            }

          }
        }
        > li:nth-child(1) {

          > a {
            font-size: 25px;
            > span {
              font-size: 15px;
            }
          }

        }
        > li:nth-child(2) {
          $color: $primer_ciclo;
          > a {
            font-size: 15px;
            > span {
              font-size: 25px;
            }
          }

        }
        > li:nth-child(3) {

          > a {
            font-size: 15px;
            > span {
              font-size: 25px;
            }
          }

        }

        > li:nth-child(4) {

          > a {
            font-size: 25px;
            > span {
              font-size: 15px;
            }
          }

        }
        > li:nth-child(5) {

          > a {
            font-size: 25px;
            > span {
              font-size: 15px;
            }
          }

        }
      }
    }
    @media screen and (max-width: $break-small) {
      margin-bottom: 100px;

      > ul {
        flex-wrap: wrap;
        > li {
          margin: 10px 0;
          min-height: 30px;

          -webkit-transition:none;
          transition:none;
          width: 100%;
          padding: 0px 0 0px 0;
          .dropdown{
            position: relative;
            top:0;

          }
          &:hover {
            -webkit-transform: none !important;
            transform: none;
            .dropdown {
              display: none;

            }
          }
        }
      }
    }
  }

}