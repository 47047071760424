@charset "UTF-8";

@font-face {
  font-family: "mandioca";
  src:url("fonts/mandioca.eot");
  src:url("fonts/mandioca.eot?#iefix") format("embedded-opentype"),
  url("fonts/mandioca.woff") format("woff"),
  url("fonts/mandioca.ttf") format("truetype"),
  url("fonts/mandioca.svg#mandioca") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "mandioca" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "mandioca" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-audio:before {
  content: "\61";
}
.icon-audiovisual:before {
  content: "\62";
}
.icon-didactico:before {
  content: "\63";
}
.icon-produccion-digital:before {
  content: "\64";
}

.icon-digital:before {
  content: "\64";
}
.icon-download:before {
  content: "\65";
}
.icon-facebook:before {
  content: "\66";
}
.icon-heart:before {
  content: "\67";
}
.icon-interactivo:before {
  content: "\68";
}
.icon-mail:before {
  content: "\69";
}
.icon-note:before {
  content: "\6a";
}
.icon-twitter:before {
  content: "\6b";
}
.icon-zoom:before {
  content: "\6c";
}
.icon-cleannote:before {
  content: "\6d";
}
.icon-close:before {
  content: "\6e";
}
.icon-gear:before {
  content: "\6f";
}
.icon-home:before {
  content: "\70";
}
.icon-logout:before {
  content: "\71";
}
.icon-user:before {
  content: "\72";
}
.icon-on:before {
  content: "\73";
}
.icon-heartfull:before {
  content: "\74";
}
