.subjects-container {

  .subject-item {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    position: relative;
    min-height: 25px;
    margin: 10px;

    &:hover{
      background: #fa6b12;
      a{
        color:#fff;
      }
    }
    a {
      color: #333;
      font-weight: 500;
      position:absolute;
      width:100%;
      top:10px;
      left:0;
      height: 100%;
      text-align: center;

    }
  }

  @media screen and (min-width: $break-small) {

    .subject-item {

    }
  }

  @media screen and (min-width: $tablet-small) {
    display: flex;
    flex-wrap: wrap;
    //justify-content: space-between;
    .subject-item {
      width: 20%;
      margin: 10px;
      a {

      }

    }
  }

}