.cancionero, .audiocuento {
  .cancionero-container {
    //background: #fff;
    //padding: 20px;
  }
  .cancionero-item {
    figure {
      margin: 0;
      padding: 0;
      img {
        max-width: 100%;
      }
    }
    .actions {
      display: flex;
      justify-content: space-between;
      padding: 10px 0;
      i {
        font-size: 20px;
        cursor: pointer;
        &.disabled {
          color: #ccc;
        }
        &:hover {
          color: #00acd6;
        }
      }
    }

  }

}

@supports (display: grid) {

  @media only screen and (min-width: 30em) {
    .cancionero-container {
      display: flex;
      flex-wrap: wrap;
      article {
        width: 20%;
        margin: 20px;

      }
    }
  }

}



