
.contact-container {

  .alert.alert-danger{
    font-size: 12px;

  }
  display: flex;
  justify-content: space-between;

  .left, .right {
    min-height: 500px;
    //max-height:600px;
    //overflow-y: scroll;
    background: #fff;
    width: 48%;
    box-sizing: border-box;
    padding: 0 35px;
    &.wide {
      //padding: 0 35px;
    }
    &.centered {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      h3 {
        text-align: center;
        width: 100%;
      }
      p {
        text-align: center;
        width: 100%;
      }
    }
  }

  .left {

    background: #fff;

    p {
      margin: 40px 0 40px;
      font-weight: 400;
      font-size: 14px;

    }


    h1 {
      color: #249eac;
      width: 100%;
      font-weight: 300;

    }
    form {
      margin: 20px 0;
      label {
        font-size: 14px;
        font-weight: 400;

      }
      .checkbox {
        border: none;
        padding: 0;
        margin: 0;
        .col {
          display: inline-block;
          width: 49%;

        }

        label {
          width: 100%;
          display: block;
          margin: 10px 0 10px 0;
        }
        .checkbox-item {
          width: 100%;
          margin-bottom: 10px;
          label {
            font-weight: 400;
          }
        }
        &.columns-2 {
          display: flex;
          flex-wrap: wrap;
          .checkbox-item {
            width: 50%;
          }
        }

      }
      .field-item {
        display: flex;
        flex-wrap: wrap;
        position: relative;

        input {
          border: 0;
          outline: 0;
          background: transparent;
          border-bottom: 1px solid black;
          width: 90%;
          margin-bottom: 14px;
          font-size: 14px;
          padding: 4px 4px;
          font-weight: 400;

        }

        textarea{
          width: 90%;
          font-size: 14px;
          padding: 3px;
          font-weight: 300;
        }
        span.required-dot {
          color: #f00;
          font-size: 14px;
          font-weight: 100;
          position: absolute;
          top: 6px;
          left: -10px;
          height: 100%;
          width: 15px;
          //left: 5px;

        }

      }
      .submit-container {
        width: 90%;
        text-align: right;
        margin-top: 20px;
        input[type=submit] {

          background-color: #00acd6;
          border-width: 0px;
          border-style: none;
          border-color: none;
          -o-border-image: none;
          border-image: none;
          border-radius: 10px;
          font-size: 11px;
          /* padding: 5px 10px; */
          color: #fff;
          cursor: pointer;
          width:60px;
          display: none;
        }
      }

    }
  }
  .right {
    padding: 0 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    h3{
      text-align: center;
      font-size: 18px;
      font-weight: 300;
    }
    h2 {
      width: 100%;
      text-align: center;

      //text-align: center;

      margin-top: 25px;
      color: #249eac;
    }

    select {
      padding-bottom: 10px;
    }
    span.required-dot {
      width: 5%;
    }
    .leyend {
      font-size: 11px;
      font-weight: 600;
      span {
        color: #ff0000;
        position: relative;
        top: 4px;
      }
    }

    .textarea-container{
      width: 100%;
      textarea{
        width:100%;
        height:300px;
        font-size: 14px;
        padding: 4px 4px;
        font-weight: 400;
      }

      .submit-container {
        width: 90%;
        text-align: right;
        margin-top: 20px;
      .button{
        background-color: #00acd6;
        border-width: 0px;
        border-style: none;
        border-color: none;
        -o-border-image: none;
        border-image: none;
        border-radius: 10px;
        font-size: 11px;
        padding: 5px 10px;
        color: #fff;
        cursor: pointer;
        width: 60px;
        text-align: center;
      }
      }
    }


  }
  @media screen and (max-width: 900px) {
    flex-wrap: wrap;

    .left, .right {
      min-height: 500px;
      background: #fff;
      width: 100%;
      box-sizing: border-box;
      padding: 0 30px;

    }
  }
  @media screen and (max-width: $break-small) {
    flex-wrap: wrap;
    .left, .right {
      min-height: 500px;
      background: #fff;
      width: 100%;
      box-sizing: border-box;
      padding: 0 30px;

    }
  }

}

.styled-checkbox {
  margin: 0;
  padding: 0;
  width: 22px;
  height: 22px;
}