.welcome-user{
  min-height: 590px;
  display: flex;
  justify-content: center;
  align-items: center;
  .welcome-block{
    text-align: center;

  }
  i{
    font-size: 80px;
    line-height: 50px;
    color:#259eac;
  }
  h2{
    width: 100%;
    margin: 0;
    font-weight: 400;

  }
  h4{
    width:100%;
    margin: 0;
    font-weight: 400;



  }
}
.registro-container {
  display: flex;
  justify-content: space-between;

  .left, .right {
    min-height: 500px;
    //max-height:600px;
    //overflow-y: scroll;
    background: #fff;
    width: 48%;
    box-sizing: border-box;
    padding: 0 35px;
    &.wide {
      //padding: 0 35px;
    }
    &.centered{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      h3{
        text-align: center;
        width:100%;
      }
      p{
        text-align: center;
        width:100%;
      }
    }
  }

  .left {

    background: #fff;
    .image-holder {
      width: 100%;
      display: flex;
      justify-content: center;
      figure {
        width: 160px;
        height: 160px;
        padding: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        background: #ccc;
        border:1px solid #ccc;

        img {
          font-size: 0px;
          width: 160px;
          position: relative;
          top: 0px;
          display: block;
          height: 160px;
          text-align: center;
          color: #fff;
          cursor: pointer;
          //text-shadow: 0px 4px 0px currentColor;

        }

      }
    }

    p {
      margin: 40px 0 40px;
      font-weight: 400;
      font-size: 14px;

    }
    .register-check-list{
      ul {
        padding: 0 30px 0 30px;
        margin: 0;
        li {
          list-style-type: none;
          font-size: 13px;
          margin-bottom: 4px;
          font-weight: 400;
        }
        li:before {
          content: '✓';
          margin-left: -10px;
          font-size: 10px;
          color: #249eac;
        }
      }
    }



    h1 {
      color: #249eac;
      width: 100%;
      text-align: center;

    }
    .registro-pagination {
      display: flex;
      justify-content: center;
      padding: 40px 0;

      .number {
        background-color: #91c5c5;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        color: #fff;

        span {
          font-size: 30px;
          font-weight: 600;

        }
        &.active {
          color: #000;

        }

      }
      .separator {
        width: 20%;
        border-bottom: 2px solid #91c5c5;
        top: -17px;
        position: relative;
      }
    }
  }
  .right {
    padding: 0 80px;

    h2 {
      width: 100%;
      //text-align: center;

      margin-top: 25px;
      color: #249eac;
    }


    select{
      padding-bottom: 10px;
    }
    span.required-dot {
      width: 5%;
    }
    .leyend {
      font-size: 11px;
      font-weight: 600;
      span {
        color: #ff0000;
        position: relative;
        top: 4px;
      }
    }
    form {
      margin: 20px 0;
      label {
        font-size: 14px;
        font-weight: 600;
      }
      .checkbox {
        border: none;
        padding: 0;
        margin: 0;
        .col{
          display: inline-block;
          width: 49%;

        }

        label {
          width: 100%;
          display: block;
          margin: 10px 0 10px 0;
        }
        .checkbox-item{
          width:100%;
          margin-bottom: 10px;
          label{
            font-weight: 400;
          }
        }
        &.columns-2{
          display: flex;
          flex-wrap: wrap;
          .checkbox-item{
            width:50%;
          }
        }

      }
      .field-item {
        display: flex;
        flex-wrap: wrap;
        position: relative;

        input {
          border: 0;
          outline: 0;
          background: transparent;
          border-bottom: 1px solid black;
          width: 90%;
          margin-bottom: 14px;
          font-size: 14px;
          padding: 4px 4px;
          font-weight: 400;

        }
        span.required-dot{
          color: #f00;
          font-size: 14px;
          font-weight: 400;
          position: absolute;
          top: 6px;
          left: -10px;
          height: 100%;
          width: 15px;
          //left: 5px;

        }

      }
      .submit-container {
        width: 100%;
        text-align: right;
        margin-top: 20px;
        input[type=submit] {

          background-color: #00acd6;
          border-width: 0px;
          border-style: none;
          border-color: none;
          border-image: none;
          border-radius: 10px;
          font-size: 11px;
          padding: 5px 10px;
          color: #fff;
          cursor: pointer;
        }
      }

    }

  }
  @media screen and (max-width: $break-large) {
    flex-wrap: wrap;

    .left, .right {
      min-height: 500px;
      background: #fff;
      width: 100%;
      box-sizing: border-box;
      padding: 0 30px;

    }
  }
  @media screen and (max-width: $break-small) {
    flex-wrap: wrap;
    .left, .right {
      min-height: 500px;
      background: #fff;
      width: 100%;
      box-sizing: border-box;
      padding: 0 30px;

    }
  }


}

.styled-checkbox{
  margin: 0;
  padding: 0;
  width: 22px;
  height: 22px;
}