.perfil-container {
  .left {
    padding: 10px;
  }

  .perfil-navigation {
    > ul {
      padding: 0;
      margin: 0;
    }
    .perfil-item {
      list-style-type: none;
      margin-bottom: 10px;
      a {
        color: #000;
        &:hover {
          color: #00acd6;
        }
      }

      .trigger {
        cursor: pointer;
        display: flex;
        flex-wrap: wrap;
        &:hover {
          color: #00acd6;
        }

        span {
          width: 7%;
          i {
            font-size: 20px;
          }

        }
        .title {
          width: 83%;
          font-weight: 600;
          font-size: 16px;

        }
        &.active {
          color: #f00;
          a {
            color: #f00;
          }
        }
      }
      .dropdown {
        > ul {
          //margin-bottom: 10px;
          margin-left: 30px;
          > li {
            list-style-type: none;
            border-bottom: 1px solid #ccc;
            height: 27px;
            margin-top: 6px;
            a {
              color: #000;
              &:hover {
                color: #00acd6;
              }
              &.active {
                color: #f00;
                font-weight: 600;

              }
            }
          }
        }
      }
    }
  }
}

.perfil-audiovisual {
  .right {
    //padding: 0 20px;
  }
  .audiovisual-grid {
    display: flex;
    flex-wrap: wrap;
    .audiovisual-item {
      width: 41%;
    }
  }

}

@media screen and (min-width: $break-small) {
  .perfil-container {
    .left {
      padding: 0 30px;
    }
    i {
      font-size: 12px;
    }
  }

}

@media screen and (min-width: $tablet-small) {
  .perfil-container {
    .left {
      width:48%;
      padding: 0 60px;
    }
    .right{
      width:48%;

    }
  }
}


