.cancionero-single-container {

  .cancionero-main {
    width:100%;
    margin-bottom: 20px;
    figure {
      margin: 0 0 30px 0;

      img {
        max-width: 100%;
      }
    }
    .player {

    }
    .actions-container {

      margin: 10px 0;
      .actions {
        display: flex;
        max-width: 100%;

        * {
          width: 18%;
        }
      }
    }

  }
  .cancionero-sidebar {
    background: #e6e6e6;
    padding: 10px 10px;
    font-size: 14px;
    color: #000;

    p {
      font-size: 14px;
      color: #000;
    }

  }

}

@media only screen and(min-width: 40em) {
  .cancionero-single-container {
    display: flex;
    .cancionero-main {
      width: 60%;
      margin-bottom: 100px;
      padding-right: 30px;
      .actions-container {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        margin: 20px 0;
        .actions {
          display: flex;
          justify-content: space-between;

          * {
            font-size: 20px;
            flex: 1;
            margin-left: 5px;
          }
        }
      }

    }
    .cancionero-sidebar {
      flex: 1;
      padding: 40px 60px;

      p {
        font-size: 14px;
      }

    }

  }
}

//@supports (display: grid) {

  @media only screen and (min-width: 30em) {
    .cancionero-single-container {
      //display: flex;


    }
  }

//}