*[data-remodal-id="note-modal"]{
  border-radius: 15px;
  background: #e6e6e6;
  padding:10px!important;
  h3{
    color:#26a9b3;
  }
  button{
    color:#fff;

  }
  .register-button{
    background: #b3b3b3;

    a{
      color:#000;
    }
  }
  .form-group{
    text-align: center;
    margin-bottom: 10px;

    label{
    }
    input{
      margin-top: 10px;
      border: 0;
      outline: 0;
      background: transparent;
      border-bottom: 1px solid black;
      width: 90%;
      margin-bottom: 14px;
      font-size: 14px;
      padding: 4px 4px;
      font-weight: 400;
    }
    input[type="submit"]{
      margin-top: 10px;

    }

  }
}