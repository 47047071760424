.login-form-container {
margin-top: 40px;
  input {
    height: 30px;
    font-size: 16px;
    width: 100%;
  margin: 10px 0;
  }
  .btn-link{
    font-size: 14px;
    color:#00a7d0;
  }
  .checkbox {

    display: flex;
    justify-content: space-between;
    margin-bottom:20px;
    label {
      width: 100%;
    }
    input {
      margin: 0;
      width: 7%;
      position: relative;
      top: 8px;

    }
  }
  form {
    width: 320px;
    margin: 0 auto;

  }
}