.styled-checkbox {
  position: absolute;
  opacity: 0;
  z-index: 10;
  cursor: pointer;
}

.styled-checkbox + label {
  position: relative;
  cursor: pointer;
  padding: 0;
}

.styled-checkbox + label:before {
  content: '';
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 20px;
  height: 20px;
  border: 1px solid #eee;
  background: white;
}

.styled-checkbox:hover + label:before {
  background: #79bbb5;
}

.styled-checkbox:focus + label:before {
  //box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}

.styled-checkbox:checked + label:before {
  background: #79bbb5;
}

.styled-checkbox:disabled + label {
  color: #b8b8b8;
  cursor: auto;
}

.styled-checkbox:disabled + label:before {
  box-shadow: none;
  background: #ddd;
}

.styled-checkbox:checked + label:after {
  content: '';
  position: absolute;
  z-index: 3;
  left: 5px;
  top: 9px;
  background: white;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
button{
  background-color: #91c5c5;
  border-width: 0px;
  border-style: none;
  border-color: none;
  border-image: none;
  border-radius: 10px;
  font-size: 11px;
  padding: 5px 10px;
  color: #fff;
  cursor: pointer;
  &:focus{
    outline:none;
  }
}