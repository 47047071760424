.homepage {
  //background: url('../img/fondo.jpg') no-repeat;
  //background-size: cover;
  height: 100vh;
  max-height: 100vh;
  background-attachment: fixed;

  //overflow: hidden;
  .main-container {
    z-index: 2;
    position: relative;
    max-height: 100vh;
    z-index: 6;

  }

  .mobile-fixed-background {
    diplay: none;
  }

  .mobile-fixed-overlay {
    diplay: none;
  }
  .background-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 1;

  }

  .content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .splash {
      //display: flex;
      //justify-content: center;
      width: 100%;
      margin-top: 6vh;
      text-align: center;
      img {
        max-width: 45%;
        //width: 400px;
        //height: 200px;
      }

    }
    .logo-text {
      color: #fff;
      font-weight: 900;
      font-size: 81px;
      position: relative;
      top: -6vh;
    }
    .splash-bajada {
      margin-top: 2vh;
      width: 100%;
      display: flex;
      justify-content: center;

      .inner-bajada {
        background: #fdcc01;
        color: #000;
        font-weight: 900;
        padding: 5px 10px;
        font-size: 24px;
        padding: 4px 25px 6px 25px;
        border-radius: 22px;

        span {
          font-weight: 400;
          font-style: italic;
        }
      }
    }
    .home-search-box {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 8vh;
      position: relative;
      z-index: 2;
      form {

        width:50%;
        input {
          width: 100%;
          border-radius: 15px;
          padding: 7px 10px;
          border: none;
          font-size: 14px;
          position: relative;
          left: 1%;

          &:focus {
            outline: none;
          }
        }
        input[type="submit"]{
          display: none;
        }
        .icon-zoom {
          line-height: 1;
          width: 20px;
          font-size: 25px;
          position: absolute;
          left: 74.2%;
          top: 4px;
          cursor: pointer;
        }
      }
    }
  }

  @media screen and (max-width: $break-large) {
    .content {

      .splash {
        margin-top: 3vh;
        img {
          max-width: 23%;
        }

      }
      .logo-text {
        font-size: 20px;
      }
      .splash-bajada {
        margin-top: 4vh;

        .inner-bajada {
          font-size: 14px;

          span {
          }
        }
      }
      .home-search-box {
        margin-top: 21vh;

        input {

        }
        .span.icon-zoom {
          display: none;
        }
      }
    }
  }
  @media screen and (max-width: $break-small) {
    height: auto;
    max-height: inherit;

    .content .home-search-box .icon-zoom {
      display: none;
    }
    .content {

      .splash {

        img {
          max-width: 60%;
        }

      }
      .logo-text {
        margin-top: 40px;
        font-size: 30px;
      }
      .splash-bajada {
        margin-top: 0vh;

        .inner-bajada {
          font-size: 10px !important;

          span {
          }
        }
      }

    }

    .mobile-fixed-overlay {
      display: block;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      position: fixed;
      background: rgba(0, 0, 0, 0.6);
      background-size: cover;
      z-index: 6;
    }

    .mobile-fixed-background {
      display: block;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      position: fixed;
      //background: url('../img/fondo.jpg') no-repeat;
      //background-size: cover;
      z-index: 3;
    }
    .content .home-search-box {

      display: none;
    }
  }

}