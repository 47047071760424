.footer{
  background: #656565;
  color:#fff;
  position: fixed;
  left:0;
  bottom:0;
  width:100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height:20px;
  z-index: 2;

  .footer-actions-container{
    font-size:12px;
    width:100%;
    max-width: $max-width-container;

  }
  @media screen and (max-width: $break-small) {
  display: none;
  }
  }
