.audiovisual-grid {

  .audiovisual-item {
    margin-top: 20px;
    box-sizing: border-box;
    figure {
      margin: 0;
      padding: 0;
      overflow: hidden;
      cursor: pointer;

      img {
        max-width: 100%;
        transition: all .2s ease-in-out;
        &:hover {
          -ms-transform: scale(1.1); /* IE 9 */
          -webkit-transform: scale(1.1); /* Safari */
          transform: scale(1.1);
        }
      }
    }
    .article-header {
      padding: 5px;
      .title {
        font-size: 14px;
        border-bottom: 1px solid #ccc;
        padding-bottom: 2px;
      }
      .actions {

        display: flex;
        justify-content: space-between;
        padding: 10px 0;
        i {
          font-size: 20px;
          cursor: pointer;
          &.disabled {
            color: #ccc;
          }
          &:hover {
            color: #00acd6;
          }
        }
      }
    }
  }
  @media screen and (max-width: $tablet-small) {
    .audiovisual-item {
      width: 100%;
    }

  }
  @media screen and (max-width: $break-small) {
    .audiovisual-item {
      width: 100%;
    }

  }
}

.audiovisual-single {
  padding: 0 90px;
  .audiovisual-info {
    display: flex;

    .left {
      width: 80%;
    }
    .right {
      margin-right: auto;
      text-align: right;
      width: 20%;
      .actions {
        width: 100%;
        margin: 20px 0;

        display: flex;
        justify-content: space-between;
        i {
          font-size: 25px;
          cursor: pointer;

          &.disabled {
            color: #ccc;
          }
          &:hover {
            color: #00acd6;
          }
        }
      }
    }
  }

  @media screen and (max-width: $break-large) {
    padding: 0 35px;
    .audiovisual-info {
      display: flex;
      flex-wrap: wrap;

      .left {
        width: 70%;
      }
      .right {
        width: 30%;

        .actions {

          i {

            &.disabled {
            }
            &:hover {
            }
          }
        }
      }
    }

  }
  @media screen and (max-width: $break-small) {
    padding: 0 10px;
    .left {
      width: 100%;
    }
    .right {
      width: 100%;
    }

  }
}

@supports (display: grid) {

  @media only screen and (min-width: 30em) {
    .audiovisual-grid {
      //display: grid;
      //grid-template-columns: 1fr 1fr 1fr;
      //grid-column-gap: 100px;
      //grid-row-gap: 40px;
      display: flex;
      flex-wrap: wrap;
      article{
        //margin:10px;
        width: 20%;
        margin: 20px;
      }
    }
  }

  @media only screen and (min-width: 70em) {
    .audiovisual-grid {
      //display: grid;
      //grid-template-columns: 1fr 1fr 1fr 1fr;
      //grid-column-gap: 100px;
      //grid-row-gap: 40px;
      display: flex;
      flex-wrap: wrap;
      article{
        //margin:10px;
        width: 20%;
        margin: 20px;
      }
    }
  }

}


.mobile-leyend{
  display: none!important;
}

@media screen and (max-width: 1000px) {

  .interactivo-single {

    display: none;
  }
  .mobile-leyend{
    display: flex!important;
  }

}


i[data-fav="true"]{
  &:before{
    content: "\74";
    color:#f00;
  }
}

i[data-note="true"]{
  &:before{
    color: #f00;
  }
}