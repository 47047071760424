.guides-grid {

  article {
img {
  max-width: 100%;
}
    a{
      color:#333;
    }
  }
}

@media screen and (min-width: $break-small) {
  .guides-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    article {
      width: 40%;
      margin-bottom: 25px;

    }
  }

}

@media screen and (min-width: $tablet-small) {
  .guides-grid {

    article {

    }
  }

}
