.fixed-header {
  position: fixed;
  background: #fff;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2222222;

  font-size: 12px;
  display: flex;
  justify-content: center;
  height: 26px;
  align-items: center;
  .header-actions-container {
    color: #ff0000;
    width: 100%;
    max-width: $max-width-container;

    //display: flex;
    //justify-content: space-between;
    display: grid;
    grid-template-columns: 3fr 6fr 3fr;
    .right-side {
      .icon-home {
        font-size: 14px;
        position: relative;
        top: 3px;
      }
      a {
        color: #000;
        &:hover {
          color: #ff0000;

        }
      }

    }
    .header-search{
      color:#000;
      text-align:right;
      margin-right:20px;
      span{
        position: relative;
        top:3px;
      }
      form{
        height: 0;
        margin-top: -17px;


        input{
          width:0;
          -webkit-transition: width 1s; /* Safari */
          transition: width 1s;
          visibility:hidden;



        }
        input[type="submit"]{
          display: none;
        }
      }

      &:hover{
        color:#fff;

        input{
          width:200px;
          visibility:visible;

        }
      }
    }
    .left-side {
      position: relative;
      .icon-facebook {
        font-size: 14px;
        position: relative;
        top: 3px;
      }
      a {
        color: #000;
        &:hover {
          color: #ff0000;

        }
      }

    }
  }
  @media screen and (max-width: $break-large) {
    .header-actions-container {
      padding: 0 10px;
    }
  }
  @media screen and (max-width: $break-small) {
    display: none;
  }
}

.acceso-docente {
  position: absolute;
  top: 24px;
  width: 100%;
  text-align: center;
  background: rgba(255, 255, 255, .76);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  height: 40px;
  padding-top: 10px;
  a {
    font-size: 17px;
    text-transform: uppercase;
    font-weight: 900;
    color: #249eac !important;
    &:hover {
      color: #2ab7c7 !important;
    }
  }
  &:hover {
    .docente-dropdown {
      display: block;
    }
  }

  .docente-dropdown {
    display: none;
    background: rgba(255, 255, 255, .76);
    width: 80%;
    position: absolute;
    margin: 0 auto;
    top: 100%;
    left: 11%;
    > ul {
      margin: 0;
      padding: 0;

      > li {
        list-style-type: none;
        text-align: left;
        padding: 5px 10px;

        i {
          font-size: 15px;
          position: relative;
          top: 3px;
        }
        a {
          font-size: 10px;
          font-weight: 400;
          padding-left: 5px;
        }

      }
    }
  }

}