$color : $primer_ciclo;

.primer-ciclo{
  &.color{
    color:$color;

  }
  &.background{
    background:$color;

  }
}


$color : $inicial;

.nivel-inicial{
  &.color{
    color:$color;

  }
  &.background{
    background:$color;

  }
}


$color : $segundo_ciclo;

.segundo_ciclo{
  &.color{
    color:$color;

  }
  &.background{
    background:$color;

  }
}
.segundo-ciclo{
  &.color{
    color:$color;

  }
  &.background{
    background:$color;

  }
}
$color : $secundaria_basica;

.secundaria_basica{
  &.color{
    color:$color;

  }
  &.background{
    background:$color;

  }
}
.secundaria-basica{
  &.color{
    color:$color;

  }
  &.background{
    background:$color;

  }
}
$color : $secundaria_superior;

.secundaria_superior{
  &.color{
    color:$color;

  }
  &.background{
    background:$color;

  }
}


.secundaria-superior{
  &.color{
    color:$color;

  }
  &.background{
    background:$color;

  }
}
