.mobile-menu {
  position: absolute;
  top: 20px;
  right: 20px;
  height: 50px;
  width: 50px;
  z-index: 999999999;
  display: none;

  button {

    &:focus {
      outline: none;
    }
    &.is-active {
      .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
        background-color: #00acd6;

      }
    }
  }
  @media screen and (max-width: $break-small) {
    display: block;

  }
}

header {

  background: url(../img/header-bkg.jpg) no-repeat;
  min-height: 300px;
  display: flex;
  justify-content: center;
  background-size: cover;
  background-position: center;
  position: relative;
  border-bottom: 10px solid #333;
  .header-overlay {
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 1;

  }

  .header-container {
    position: relative;
    z-index: 2;
    width: $max-width-container;
    margin-top: 30px;
    //color: #fff;
    display: flex;
    flex-wrap: wrap;

    .header-up {
      display: flex;
      align-items: flex-end;
      width: 100%;
      height: 159px;

      .left {
        .logo-container {

          text-align: center;
          width: 40%;


          img {
            max-width: 100%;
            position: relative;
            top:-25px;
          }
          .logo-text {
            font-size: 35px;
            font-weight: 900;
            position: relative;
            top: -6px;
            color: #fff;
            line-height: 60px;
          }
          .logo-bajada {
            background: #fdcc01;
            color: #000;
            margin-top: -10px;
            font-weight: 900;
            padding: 5px 10px;
            font-size: 10px;
            //padding: 4px 25px 6px 25px;
            border-radius: 22px;

            span {
              font-weight: 400;
              font-style: italic;
            }
          }
        }

      }

      .right {
        margin-left: auto;
        width: 70%;
        .home-search-box {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          //margin-top: 11vh;
          position: relative;
          form {
            width: 100%;

            input {
              width: 100%;
              border-radius: 15px;
              padding: 7px 10px;
              border: none;
              font-size: 14px;
              position: relative;
              left: 1%;

              &:focus {
                outline: none;
              }
            }
            .icon-zoom {
              line-height: 1;
              width: 20px;
              font-size: 22px;
              top: 5px;
              position: absolute;
              left: 96.3%;
              cursor: pointer;
            }
            input[type="submit"]{
              display: none;
            }
          }
        }
      }
    }
    .header-down {
      color: #fff;
      width: 100%;
      align-self: flex-end;

    }
  }
  @media screen and (max-width: $break-large) {
    .header-container {
      padding: 0 10px;
      .header-up {
        .right {
          .home-search-box {
            input {

              &:focus {
              }
            }
            .icon-zoom {
              left: 94.3%;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: $break-small) {
    min-height: 200px;
    justify-content: center;
    background-size: cover;
    background-position: center top;
    .header-container {

      .header-up {
        .left {
          width: 100%;
          display: flex;
          justify-content: center;

          .logo-container {
            width: 65%;
            .logo-text {
              top: 0px;
              font-size: 28px;

            }

          }
        }
        .right {
          display: none;
        }
      }
    }

  }
}
