
// Fonts
//@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i');
// Variables
@import "variables";
@import "fonts";


@import "parts/main";
@import "parts/shared/colors";
@import "parts/shared/elements";
@import "parts/shared/header";
@import "parts/shared/footer";
@import "parts/shared/navigation";
@import "parts/shared/inside-header";
@import "parts/shared/inside-navigation";
@import "parts/shared/mobile-navigation";
@import "parts/shared/login-modal";
@import "parts/shared/note-modal";

@import "parts/pages/home";
@import "parts/pages/materia/collection";
@import "parts/pages/materia/audiovisual";
@import "parts/pages/materia/level";
@import "parts/pages/registro/registro";
@import "parts/pages/perfil/perfil";
@import "parts/pages/perfil/guides";
@import "parts/pages/auth/login";
@import "parts/pages/cancionero/collection";
@import "parts/pages/cancionero/single";
@import "parts/pages/contact";
@import "parts/shared/pagination";


// Bootstrap
@import "vendor/remodal";
@import "vendor/remodal-default-theme";
@import "vendor/hamburguers";


.interactivo-single button{
  font-family: 'system-ui'!important;
}