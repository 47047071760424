body {
  background: #eee;
  font-family: 'Open Sans', sans-serif;
  font-weight: 100;
  margin: 0;
  padding: 0;
}

a, a:visited, a:focus {
  text-decoration: none;

}

.title a {
  color: black;
}

.breadcrumb {
  font-size: 14px;
  padding: 30px 0;
  text-transform: uppercase;
}

.box {
  background: #fff;
  padding: 10px 20px;
  margin-bottom: 50px;
  @media screen and (max-width: $break-small) {
    padding: 10px 10px;

  }

}

.box-transparent {
  background: transparent;
  padding: 10px 30px;
  margin-bottom: 50px;
  @media screen and (max-width: $break-small) {
    padding: 10px 10px;

  }

}

.strong {
  font-weight: 600;
}

.wrapper {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  .main-container {
    width: 100%;
    max-width: $max-width-container;

    .content {
      //background: #eee;

    }
  }

}

.flex-video {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
}

.flex-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.prox-container {
  padding: 100px 0;
  font-size: 26px;
  font-family: 'Open Sans', sans-serif;
  text-align: center;
  font-weight: 600;

}

@media screen and (max-width: $break-small) {

  body {
    background: #000;

  }
}

.pagination-secuencia {
  text-align: right;
  padding: 0 3%;
  a {
    background: #187dcd;
    color: #fff;
    font-size: 12px;
    padding: 4px 11px;
    border-radius: 9px;

  }
}

.docente-dropdown {
  z-index: 1;
}

.login-box-docente {
  padding-bottom: 5px;
  i {
    font-size: 12px !important;
    color: #000;
  }
}

.down-login-box-docente {
  margin-top: 4px;
  border-top: 1px solid #fff;
  padding-top: 5px;

  span {
    width: 50%;
    text-align: center;

    a {
      text-align: center;
      color: #000 !important;
      font-size: 12px !important;
      padding-left: 0 !important;
      //background: #ccc;
      width: 100%;
    }
  }

}

.game-button {

  display: flex;
  color: #fff;
  padding: 20px;

  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  text-align: center;
  align-self: center;
  justify-content: center;
  border-radius: 10px;
  min-height: 60px;
  flex-direction: column;
  font-size: 20px;
  &:hover {
    filter: brightness(130%);
  }

  .game-button-inner {
    height: auto;

  }

}
.interactivo-single{
  .pagination{
    display: none!important;
  }

}
.quiz .dnd1-game-actions{
  display: none;
}