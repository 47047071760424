.pagination-container {
  margin:30px 0;
  display: flex;
  align-items: center;

  .pagination {
    text-align: left;
    padding: 0;
    //width: 200px;
    margin: 0 auto;
    justify-content: center;

    display: -ms-flexbox;
    display: flex;
    list-style: none;
    li {
      border-radius: 50%;
      padding: 4px 9px;
      font-size: 12px;
      margin: 0 3px;
a{
  color:#000;

}

      &:nth-child(1) {
        padding: 4px 9px;
        font-size: 12px;
      }
      &:nth-last-child(1) {
        padding: 4px 9px;
        font-size: 12px;
      }

      &.active {
        background: #5792ff;
        color: #fff;
      }
      &:hover{
        background: #333;
        a{
          color:#fff;
        }
      }
      &.disabled {
        color: #eee;
      }
    }

  }
}
